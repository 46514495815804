require('./../assets/vendor/modernizr/modernizr');

require('../assets/vendor/create-js/createjs');

// import lodash from 'lodash';

import $jQuery from 'jquery';

// import axios from 'axios';

// window._ = lodash;

window.$ = window.jQuery = $jQuery;

require('jquery-confirm');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests.
 */

// window.axios = axios;

/** @namespace axios.defaults */
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//require('./components/helpers/app');