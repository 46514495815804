/**
 * First we will load all of this project's JavaScript dependencies.
 */
import './bootstrap';

import {
    HelpersAllTap
} from './components/helpers';

(function (global, factory) {
    'use strict';

    if (typeof (module) === 'object' && typeof (module.exports) === 'object') {
        /**
         *
         * For CommonJS and CommonJS-like environments where a proper `window`
         * is present, execute the factory and get Class.
         * For environments that do not have a `window` with a `document`
         * (such as Node.js), expose a factory as module.exports.
         * This accentuates the need for the creation of a real `window`.
         * e.g. var Class = require('Class')({window: window});
         * @type {void|(function(*): void)}
         */
        module.exports = global.document ?
            factory(global, true) :
            function (w) {
                if (!w.document) {
                    throw new Error('Class requires a window with a document');
                }
                return factory(w, false);
            };
    } else {
        factory(global, false);
    }
})(typeof (window) !== 'undefined' ? window : this, function (window, noGlobal) {
    if (!noGlobal) {
        window.AllTap = HelpersAllTap({window: window});
    }
});